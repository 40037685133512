import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Marquee3k from '../utils/marquee'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import useResizeObserver from 'use-resize-observer'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeedPage = props => {
  const [containerRef, width, height] = useResizeObserver()
  useEffect(() => {
    Marquee3k.init({
      selector: 'marquee',
    })
  }, [])
  const entries = props.data.feedEntries.edges
  return (
    <>
      <div className="feed-container">
        <div className="feed-category">
          <h1>Current Releases</h1>
        </div>
        <div className="releases">
          <CarouselProvider
            naturalSlideWidth={width}
            naturalSlideHeight={height}
            totalSlides={entries.filter(({ node }) => node.frontmatter.type === 'release').length}
            isPlaying
            interval={4000}
            visibleSlides={1}
            dragEnabled={false}
            infinite="true"
          >
            <Slider classNameAnimation="_">
              {entries
                .filter(({ node }) => node.frontmatter.type === 'release')
                .map(({ node: feedEntry }, index) => (
                  <Slide
                    index={index}
                    key={feedEntry.id}
                    className="slide"
                    classNameVisible="slide--visible"
                    classNameHidden="slide--hidden"
                  >
                    <Link
                      className="releases__entry link link--nav"
                      to={feedEntry.frontmatter.path}
                      ref={containerRef}
                    >
                      <div className="releases__entry__image">
                        <PreviewCompatibleImage
                          imageStyle={{
                            position: 'relative',
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            backgroundColor: 'transparent',
                          }}
                          imageInfo={{
                            image: feedEntry.frontmatter.featuredImage,
                            alt: feedEntry.frontmatter.title,
                          }}
                        />
                      </div>
                      <div className="releases__entry__info">
                        <h3>{feedEntry.frontmatter.title}</h3>
                        <span>{feedEntry.frontmatter.description}</span>
                        <div dangerouslySetInnerHTML={{ __html: feedEntry.html }} />
                      </div>
                    </Link>
                  </Slide>
                ))}
            </Slider>
          </CarouselProvider>
        </div>
        <div className="feed-category">
          <h1>A Void Magazine</h1>
        </div>
        <div className="feed">
          {entries
            .filter(({ node }) => node.frontmatter.type === 'post')
            .map(({ node: feedEntry }) => (
              <Link className="feed__entry link link--nav" key={feedEntry.id} to={feedEntry.fields.slug}>
                <div className="feed__entry__image">
                  <PreviewCompatibleImage
                    imageStyle={{
                      position: 'relative',
                      objectFit: 'cover',
                      objectPosition: '50% 50%',
                      backgroundColor: 'transparent',
                    }}
                    imageInfo={{
                      image: feedEntry.frontmatter.featuredImage,
                      alt: feedEntry.frontmatter.title,
                    }}
                  />
                </div>
                <div className="feed__entry__title" to={feedEntry.fields.slug}>
                  <h2>{feedEntry.frontmatter.title}</h2>
                </div>
                <div className="feed__entry__date">{feedEntry.frontmatter.date}</div>
                <div className="feed__entry__exc">
                  <p>{feedEntry.excerpt}</p>
                </div>
                <div className="feed__entry__desc">
                  <p>{feedEntry.frontmatter.issue}</p>
                </div>
              </Link>
            ))}
        </div>
      </div>
      <div className="marquee-container">
        <a
          href="https://www.patreon.com/morbidbooks"
          target="_blank"
          rel="noopener noreferrer"
          className="link link--marquee"
        >
          <div className="marquee" data-speed="0.35" data-reverse={false} data-pausable={true}>
            <span>
              <span>Join our print mailing list</span>
              Join the Temple of Surrealism
            </span>
          </div>
        </a>
      </div>
    </>
  )
}

export default FeedPage

FeedPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query FeedQueryNew {
    feedEntries: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "feed-entry" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 310)
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 60) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            description
            issue
            type
            path
          }
        }
      }
    }
  }
`
